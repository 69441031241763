<template>
  <!-- 비밀번호 수정 다이올로그-->
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="bDialog" max-width="400px"  v-if="bDialog">
      <v-card >
        
        <v-card-title >
          <span class="font-weight-bold">구역정보 선택</span>
        </v-card-title>
        <v-divider></v-divider>
          <div style="overflow-x: auto; overflow-y: auto; width:100%; height:calc(100vh - 500px); padding:10px; background-color:#ffffff">
            <v-treeview     
                :active="selection"                  
                :items="items"
                activatable
                item-key="id"
                hoverable
                selection-type="independent"
                dense  
                :open.sync="open"
                @update:active="onUpdate"
                return-object>
                <!-- <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.iLev == -1 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 0 ">mdi-map</v-icon>
                  <v-icon v-else-if="item.iLev == 1 ">mdi-group</v-icon>
                  <v-icon v-else-if="item.iLev == 2 ">mdi-office-building</v-icon>
                  <v-icon v-else>mdi-floor-plan</v-icon>
                </template> -->
            </v-treeview>
            <!-- <v-treeview     
              v-model="selection"                
              :items="items"
              activatable                  
              hoverable
              dense  
              @update:active="onUpdate"
              return-object>
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.iLev == -1 ">mdi-map</v-icon>
                <v-icon v-else-if="item.iLev == 0 ">mdi-office-building-marker</v-icon>
                <v-icon v-else>mdi-floor-plan</v-icon>
              </template>
              <template v-slot:append="{ item }">
                <p class="headline mb-n1 font-weight-black">{{item.iCount}}</p>                 
                
              </template>
          </v-treeview> -->
          </div>
        <v-divider></v-divider>
        <v-card-actions>           
          <v-spacer></v-spacer>
          <v-btn color="blue darken-2" text @click="save" class="font-weight-bold text-subtitle-1" :disabled="selectId == '' ">
            <v-icon big class="mr-2">save</v-icon>확인
          </v-btn>
          <v-btn color="grey darken-2" text @click="bDialog=false" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">cancel</v-icon>취소
          </v-btn>          
        </v-card-actions>              
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EventBus from '@/eventBus.js';
import BasicInfo from "../BasicInfo.js";
import axios from "axios";


export default {
  data: () => ({
    bDialog: false,
    items: [],
    selection: [],  
    selData: {},  
    open: [],    
    selectId : "",
  }),

  created() {
    this.bDialog = false;
  },

  methods: {
    onUpdate(selection) {      
      this.selectId = "";
      
      if (selection.length > 0) {                        
        this.selectId = selection[0].id; 

        this.selData.cMapGroupId = selection[0].cMapGroupId;
        this.selData.cBuildingId = selection[0].cBuildingId;
        this.selData.cMapId = selection[0].cMapId;
        this.selData.cMapNm = selection[0].name;
        this.selData.cMapFullNm = selection[0].cMapFullNm;
      } else {
        this.selData.cMapGroupId = "";
        this.selData.cBuildingId = "";
        this.selData.cMapId = "";        
        this.selData.cMapNm = "";
        this.selData.cMapFullNm = "";
      }
    },
    
    Search() {         
      this.open = [];   
      this.items = [];
      this.selection = [];
      this.sNowMapId = "";
      this.zApPos = [];
      this.imgUrl = BasicInfo.FILE_API + "Files/NoImage.svg";

      var posData = {        
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            // console.log(res.data.Result);
            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 0) {
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  cMapFullNm: res.data.Result[i].cMapFullNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cParentNm : "",
                  children: [],
                }
                this.items.push(item);           
                
                this.open.push(item);
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 1) {                
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  cMapFullNm: res.data.Result[i].cMapFullNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cParentNm : this.items[0].name,
                  children: [],
                }
                this.items[0].children.push(item);   
                this.open.push(item);                      
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {                  
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    let item = {
                      id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                      cMapGroupId: res.data.Result[i].cMapGroupId,
                      cBuildingId: res.data.Result[i].cBuildingId,
                      cMapId: res.data.Result[i].cMapId,
                      name: res.data.Result[i].cMapNm,
                      cMapFullNm: res.data.Result[i].cMapFullNm,
                      file: res.data.Result[i].cFileId,
                      iLev: res.data.Result[i].iLev,
                      Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                      Order: res.data.Result[i].cOrder,                
                      cParentNm : this.items[0].children[j].name,
                      children: [],
                    }
                    this.items[0].children[j].children.push(item);
                    break;
                  }
                }
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 3) {
                for (let j = 0; j < this.items[0].children.length; j++) {    
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    for (let z = 0; z < this.items[0].children[j].children.length; z++) {    
                      if (this.items[0].children[j].children[z].cBuildingId == res.data.Result[i].cBuildingId) {     
                        let item = {
                          id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                          cMapGroupId: res.data.Result[i].cMapGroupId,
                          cBuildingId: res.data.Result[i].cBuildingId,
                          cMapId: res.data.Result[i].cMapId,
                          name: res.data.Result[i].cMapNm,
                          cMapFullNm: res.data.Result[i].cMapFullNm,
                          file: res.data.Result[i].cFileId,
                          iLev: res.data.Result[i].iLev,
                          Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                          Order: res.data.Result[i].cOrder,           
                          cParentNm : this.items[0].children[j].children[z].name,     
                          children: [],
                        }
                        this.items[0].children[j].children[z].children.push(item);
                        break;
                      }
                    }
                  }
                }
              }              
            }

            
            
            if (this.items.length > 0) {                      
              this.selection.push(this.items[0]);             
              this.sNowMapId = res.data.Result[0].cMapGroupId + res.data.Result[0].cBuildingId + res.data.Result[0].cMapId;
              this.imgUrl = BasicInfo.FILE_API + res.data.Result[0].cPath;                                
            }     
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },

    show() {      
      this.Search();    
      this.bDialog = true;        
    },
    
    save() {      
      this.bDialog = false;            
      
      this.$emit("onGetMap", this.selData);
    },
  },
}
</script>