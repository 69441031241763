<template>  
  <div>
    <div class="background_basic">      
      <div style="height:1px"></div>
      <v-card class="ma-4">
      <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-map-clock</v-icon>단지출입목록조회
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('단지출입목록조회')" class="ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>
        <v-spacer></v-spacer>
          
      </v-toolbar>
      <v-divider></v-divider>

      
      <v-card flat class="d-flex">
        <v-row class="ma-0">
          <IronDate ref="IronDateRef" class="ml-2 mb-n3" v-model="sNowSelectDate" @update="onDateRangeChange"  style="max-width:250px;"/>                      
          <v-select v-model="cNowType" class="ml-2 mb-n3" label="구분" :items="zCorpAll" item-text="sNm" item-value="sId"  style="max-width:150px;"></v-select>
          <v-select v-model="cNowPermission" class="ml-2 mb-n3" label="통제여부" :items="zReqStateTypeAll" item-text="sNm" item-value="sId"  style="max-width:150px;"></v-select>          
          <v-text-field
            v-model="cNowSearchKeyWord"
            class="ml-3 mb-n5"   
            label="검색어"
            placeholder="이름 or 부서 or 관리번호 입력"
            persistent-placeholder
            style="max-width:250px;"
            Regular
            v-on:keyup.enter="Search()"
          ></v-text-field>            
          <v-btn icon class="ml-n8 mt-3  text-subtitle-1" @click="Search()">
            <v-icon>search</v-icon>
          </v-btn>  
        </v-row>
      </v-card>
      </v-card>     
      
      <v-data-table 
        class="ma-4 elevation-3"
        :items-per-page="100"
        fixed-header
        height="calc(100vh - 230px)" 
        :headers="headers" 
        :items="Records"
      >
        <template v-slot:item.cResult="{ item }">
          <v-chip label small v-if ="item.cResult == 'REQ'" color="grey darken-2" dark>신청중</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'OK'" color="blue lighten-3" dark>승인</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'NG'" color="red lighten-3" dark>반려</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'HOLD'" color="black" dark>보류</v-chip>
          <v-chip label small v-else-if ="item.cResult == 'NONE'" color="red" dark>없음</v-chip>
          <v-chip label small v-else color="grey lighten-3" >{{item.cResult}}</v-chip>
        </template>
        <template v-slot:item.cOKUserNm="{ item }">
          <div label small v-if ="item.cOKUserNm == 'NONE'"></div>
          <div label small v-else >{{item.cOKUserNm}}</div>
        </template>
        
        <template v-slot:item.cEnterReqIdx="{ item }">
          <div label small v-if ="item.cEnterReqIdx == 'NONE'"></div>
          <div label small v-else >{{item.cEnterReqIdx}}</div>
        </template>

        <template v-slot:item.cInDateTime="{ item }">
          <v-chip color="grey lighten-4" label @click="moreInfo(item)">{{ item.cInDateTime}}
            <v-icon small class="mr-n2" >mdi-chevron-right</v-icon>
          </v-chip>        
        </template>
        <template v-slot:item.cOutDateTime="{ item }">
          <v-chip color="grey lighten-4" v-if="item.cOutDateTime != ''" label @click="moreInfo(item)">{{ item.cOutDateTime }}
            <v-icon small class="mr-n2">mdi-chevron-right</v-icon>
          </v-chip>        
        </template>
      </v-data-table>

    

    <v-dialog v-model="dialog" max-width="1024px">
      <v-card>
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
          상세이력
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>    
          <v-spacer></v-spacer>
          <v-btn color="black" icon @click="dialog =false" class="mr-n4"><v-icon>close</v-icon></v-btn>                          
        </v-toolbar>
        
        <v-divider ></v-divider> 
        <v-data-table 
          :items-per-page="100"
          fixed-header
          height="calc(100vh - 255px)" 
          :headers="headersDetail" 
          :items="RecordsDetail"
        >        
          <template v-slot:item.cMapFullNm="{ item }">
            <v-chip color="grey lighten-4" label @click="showPos(item)">{{ item.cMapFullNm}}
              <v-icon small class="mr-n2" >mdi-chevron-right</v-icon>
            </v-chip>        
          </template>
        </v-data-table>

      </v-card>
    </v-dialog>
    <GetSelMapOne ref="GetSelMapOneRef" @onGetMap="onGetMap" />    
    <MapViewPOS ref="MapViewPOSRef"/> 
  </div>
  </div>
</template>

<script>
import axios from "axios"
import BasicInfo from "@/BasicInfo.js"
import XLSX from 'xlsx'
import Util from "../Util.js"
import EventBus from '@/eventBus.js'
import IronDate from '../components/iron28Calendar/src/IronDate.vue'
import { mapState } from "vuex";
import MapViewPOS from "@/components/MapViewPOS.vue";
import GetSelMapOne from "@/components/GetSelMapOne.vue";


export default {
   components: {
     IronDate,    
     MapViewPOS,
     GetSelMapOne,
   },
  computed: {
    ...mapState(["zReqStateTypeAll", "zCorpAll",  "zGroupAll",]), 
  },
  data: () => ({
    cGroup : "ALL",
    isGroupALL : false,
    dialog : false,
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate :Util.sFormatDate(Date()),
    cNowSearchKeyWord : "",
    cNowLocationNm: "ALL",   
    cNowType: "ALL",    
    cNowPermission: "ALL",
    headers: [
      { class: "font-weight-bold subtitle-3", align: "center", text: '일자', value: 'cDate', width: 120},
      { class: "font-weight-bold subtitle-2", align: "center", text: '소속', value: 'cCorpNm', width: 120},
      { class: "font-weight-bold subtitle-2", align: "center", text: '부서', value: 'cPartNm', width: 120},
      { class: "font-weight-bold subtitle-2", align: "center", text: '작업자', value: 'cUserNm', width: 100},
      { class: "font-weight-bold subtitle-2", align: "center", text: '위치', value: 'cMapNm', width: 120},
      // { class: "font-weight-bold subtitle-2", align: "center", text: '출입신청일시', value: 'cCreateDateTime', width: 120},
      // { class: "font-weight-bold subtitle-2", align: "center", text: '출입승인일시', value: 'cOKDateTime', width: 120},
      // { class: "font-weight-bold subtitle-2", align: "center", text: '승인자', value: 'cOKUserNm', width: 120},      
      // { class: "font-weight-bold subtitle-2", align: "center", text: '통제여부', value: 'cResult', width: 100},
      { class: "font-weight-bold subtitle-2", align: "center", text: '관리번호', value: 'cEnterReqIdx', width: 70},
      { class: "font-weight-bold subtitle-2", align: "center", text: '출입', value: 'cInDateTime' , width: 120},
      { class: "font-weight-bold subtitle-2", align: "center", text: '퇴장', value: 'cOutDateTime' , width: 120},
      { class: "font-weight-bold subtitle-2", align: "center", text: '체류시간', value: 'cDuration' , width: 120},      
    ],
    Records: [],    
    headersDetail: [
      { class: "font-weight-bold subtitle-3", align: "center", text: '위치', value: 'cMapFullNm',},      
      { class: "font-weight-bold subtitle-2", align: "center", text: '출입', value: 'cInDateTime' },
      { class: "font-weight-bold subtitle-2", align: "center", text: '퇴장', value: 'cOutDateTime' },
      { class: "font-weight-bold subtitle-2", align: "center", text: 'AP', value: 'cApId' },
    ],
    RecordsDetail: [],        
  }),
   
  mounted () {
    if (this.$store.state.UserInfo.isGroupMode && this.$store.state.UserInfo.GroupId == "ALL") {
      this.isGroupALL = true;
      this.$store.state.GetGroupInfo(); 
    }
    this.initialize()
  },

  methods: {
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowLocation = "";    
      this.cNowLocationNm = "ALL";  
      this.cNowType = "ALL",    
      this.cNowPermission = "ALL",
      
      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Date());

      this.Search();
    },

    // 달력에서 시작~종료 날짜 선택
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },
    onNowLocationDel () {
      this.cMapGroupId = "00";
      this.cBuildingId = "00";      
      this.cMapId = "00";
      this.cNowLocationNm = "ALL"; 
    },
    selectMap () {
      this.$refs.GetSelMapOneRef.show(); 
    },
    
    onGetMap(select) {
      this.cMapGroupId = select.cMapGroupId;  
      this.cBuildingId = select.cBuildingId;  
      this.cMapId = select.cMapId;  
      this.cNowLocationNm = select.cMapNm;  
    },
  
    Search() {      
      this.Records = [];      

      var posData = {
        cStartDatetime: this.sStartDate,  
        cEndDatetime  : this.sEndDate,
        cMapGroupId : this.cMapGroupId,
        cBuildingId : this.cBuildingId,
        cMapId : this.cMapId,  
        cNowType: this.cNowType,
        cNowPermission: this.cNowPermission,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
        cGroupId: this.$store.state.UserInfo.GroupId,
      };

      if (this.isGroupALL) {
        if (this.cGroup != "ALL") {
          posData.cGroupId = this.cGroup;
        }
      }
      axios
        .post(BasicInfo.UIL_API + "GetEnterMapGroupLog", posData, {        
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") { 
            this.Records = res.data.Result; 

            for(let i = 0; i < this.Records.length; i++ ){            
              if (this.Records[i].cEnterReqIdx == "SKMR") {
                this.Records[i].cEnterReqIdx = "SKST";
              }
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
            console.log("(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);         
          console.log(err); 
        });
    },


    moreInfo(item) {      
      this.dialog = true;
      this.RecordsDetail = [];      

      var posData = {
        cStartDatetime: item.cDate,  
        cUserId : item.cUserId,        
        cMapGroupId : item.cMapGroupId,        
      };
      axios
        .post(BasicInfo.UIL_API + "GetEnterMapGroupDetailLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") { 
            this.RecordsDetail = res.data.Result; 
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
            console.log("(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);         
          console.log(err); 
        });
    },


    showPos(item){
      if (BasicInfo.Is3D_MapMode) {
        EventBus.$emit("onMapPos", item.cApId)
      } else {
        this.$refs.MapViewPOSRef.Show("위치", item, item.cPath, item.x, item.y, "", item.cMapFullNm, item.cUserNm);
      }
    },

    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

  },  
}

</script>
